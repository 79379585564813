<template>
    <div class="page-content">
        <h1 class="page-title"> How it Works </h1>

        <div style="display: flex; flex-wrap: wrap; align-items: center; justify-content: center;">
            <div style="padding: 15px; flex-grow: 4;">

                <h3 style="text-align:center; padding-top: 10px;"> FamilyTreeDNA Video Tutorial </h3>
                <iframe style="width: 100%; min-height: 300px;"  src="https://www.youtube.com/embed/5rbN9FNtk3U" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

            </div>

            <div style="padding: 15px; flex-grow: 4;">
                <h3 style="text-align:center; padding-top: 10px;"> 23andMe Video Tutorial </h3>
                <iframe style="width: 100%; min-height: 300px;" src="https://www.youtube.com/embed/Y2lGO5ELB4A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

            </div>
            

        </div>

        <div style="display: flex; flex-wrap: wrap; align-items: center; justify-content: center;">
           
            <div style="padding: 15px;">
                <h3 style="text-align:center; padding-top: 10px;"> Y-DNA Automated Analysis Tutorial</h3>
                <iframe style="width: 100%; min-height: 300px;" src="https://www.youtube.com/embed/6lv64qBXPcY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

            </div>
            

        </div>
        
    </div>
</template>

<script>
    export default {
        name: 'how-it-works',
        mounted() {
        }
    }
</script>
