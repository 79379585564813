<template>

    <div class="container mt-1">
        <div class="row">
            <div class="col pt-4">

                <!-- <h3 style="margin-left: 0px; margin-bottom: 30px;">{{title}}</h3>
                 -->
                 
                <div v-if="errorText" class="text-danger">{{errorText}}</div>
                <table v-if="!errorText" class="table table-bordered">
                    <thead>
                        <tr class="fw-bold">
                            <td>Surname</td>
                            <td>{{title || 'Feature'}}</td>
                        </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(entry, idx) in mapFeatures" :key="idx">
                        <td v-if="idx > 0">
                            {{entry[0]}}
                        </td>
                        <td v-if="idx > 0">
                            {{entry[1]}}
                        </td>
                    
                    </tr>
                    </tbody>
                </table>
            
            </div>
        </div>
        

    </div>

</template>

<script>

export default {

    name: 'FeaturesTable',

    props: {
        title: String,
        features: Array,
        error: String,
        split: {
            type: String,
            default: '-'
        }
    },

    data(){
        return {
            errorText: null
        }
    },

    methods: {
        splitFeature(feat){
            return feat.split(this.split)
        }
    },

    computed: {
        mapFeatures(){
            return this.features.map(f => {
                return this.splitFeature(f[2])
            })
        }
    },

    mounted(){
        console.log('features', this.features)
        if(!this.features || this.features.length === 1){
            this.errorText = this.error || "Unfortunately we could not find any features of this type associated with the provided surnames."
        }
    }
}

</script>