import axios from 'axios';

axios.defaults.headers['Content-Type'] = 'application/vnd.api+json';
axios.defaults.headers['Accept'] = 'application/vnd.api+json';
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.params = {};
axios.defaults.baseURL = process.env.VUE_APP_API_PATH;
if(process.env.NODE_ENV === 'production'){
    axios.defaults.withCredentials = true
    axios.defaults.xsrfCookieName = 'csrftoken'
    axios.defaults.xsrfHeaderName = 'X-CSRFToken'
}


export default axios.create({
    
});
