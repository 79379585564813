<template>
<div>
  
    <site-header></site-header>
    <router-view/>
    <div class="footer d-flex flex-row-reverse">
      <div class=""><a class="" href="/privacy-policy">Privacy Policy</a></div>
      <div class="text-white">© {{copyright}} Irish Origenes</div>
    </div>
</div>
  <!-- <div id="app">

  </div> -->
</template>

<script>

import SiteHeader from './components/SiteHeader.vue'
export default {
  components: {
    SiteHeader
  },

  data(){
    return {
      copyright: new Date().getFullYear()
    }
  }
}
</script>

<style>
@import './assets/styles/app.css';

</style>
