<template>
    <div class="page-split home-page">
          <div class="relative">
              <div class="page-split--left">
                  <div class="text-block" style="padding: 5%; padding-top: 20%;">
                      <div >
                          <h2 class="mb-3">Sorry to see you go...</h2>
                          <p>Remember you can browse for free all of the Origenes maps at <a href="https://www.origenesmaps.com" target="_blank">www.origenesmaps.com</a> or continue to generate a report by choosing an option below:</p>
                      </div>
                      
                      <a class="button" href="/v2/autosomal"> Autosomal </a>
                      <a class="button" href="upload"> Y-DNA </a>
                      <a class="button" href="surnames"> Irish Surname Search </a>
                  </div>
              </div>
  
              <div class="page-split--right"><div class="page-background-section"></div></div>
          </div>
      </div>
  </template>
  
  <script>
  // @ is an alias to /src
  
  
  export default {
    name: 'CancelPage',
    components: {
      
    }
  }
  </script>
  
  <style scoped>
  .page-background-section {
    height: 100%;
    background-image: url(/static/io/images/index.jpeg?008e147b9bcbb36a3fc2d96d5184bfe0);
    background-size: cover !important;
  }
  </style>
  