<template>
    <div class="page-content">
        <h1 class="page-title"> About Me </h1>
        <p class="paragraph-l center"> Irish Origenes was founded by Dr Tyrone Bowes in 2011 after he discovered that
            his commercial ancestral DNA test results pinpointed his Irish origin within County Laois in the Irish
            Midlands. Since 2012, Irish Origenes has completed thousands of Y-DNA and Autosomal reports for customers
            from all over the world. His most notable customers include <a
                href="https://www.facebook.com/IrishOrigenes/photos/a.326873137351758/1195072290531834/?type=3&theater"> the
                current President of Ireland Michael D. Higgins</a>, the late <a
                href="https://www.facebook.com/IrishOrigenes/photos/a.326873137351758/1277002935672102/?type=3&theater">Norman
                P. McClelland of Shamrock Foods (Philanthropist and businessman)</a>, and Professor Sir George Bain
            (formerly of Queens University). Since 2012, Irish Origenes has been actively compiling the most
            comprehensive geographical databases of Irish surnames, placenames and historical monuments in order to
            facilitate the automation of the pinpointing process. In June 2019, the databases were completed and the
            automated analysis and reporting of Irish Y-DNA test results was launched, followed by the automated
            analysis and reporting of Irish Autosomal DNA results in January 2020. Automation has now made it accessible
            for every member of the worldwide Irish Diaspora to use the results of commercial ancestral DNA tests
            (Autosomal and Y-DNA) to pinpoint where their Irish ancestors lived! What will your DNA reveal?
        </p>
    </div>
</template>

<script>
    export default {
        name: 'about-component',
        mounted() {
        }
    }
</script>
