<template>
    <div class="page-split contact-page">
        <div class="page-split--left">
            <div class="upload-container">
                <div class="mb-3">
                    <h1 class="page-title">Discover your Irish Origin.</h1>
                    <p class="">Determine the precise origin of your ancestral surname together with the location of associated castles and placenames ($40 for up to 4 surnames).</p>
                </div>

                <form v-on:submit.prevent="onSubmit">
                    

                    <div>
                        <h3 class="page-subtitle">Irish Surnames</h3>
                        <p class="under-subtitle less-bottom-padding">Enter up to 4 Irish ancestral surnames</p>

                        <div class="Input">
                            <label class="Input-label" for="fname_1">Name</label>
                            <input v-model="surnames[0]" class="Input-text" type="text" id="fname_1"
                                   autocomplete="off" placeholder="Surname 1">
                        </div>

                        <div class="Input">
                            <label class="Input-label" for="fname_2">Name</label>
                            <input v-model="surnames[1]" class="Input-text" type="text" id="fname_2"
                                   autocomplete="off" placeholder="Surname 2">
                        </div>

                        <div class="Input">
                            <label class="Input-label" for="fname_3">Name</label>
                            <input v-model="surnames[2]" class="Input-text" type="text" id="fname_3"
                                   autocomplete="off" placeholder="Surname 3">
                        </div>

                        <div class="Input">
                            <label class="Input-label" for="fname_4">Name</label>
                            <input v-model="surnames[3]" class="Input-text" type="text" id="fname_4"
                                   autocomplete="off" placeholder="Surname 4">
                        </div>

                        <!-- <div class="Input">
                            <label class="Input-label" for="fname_5">Name</label>
                            <input v-model="surnames[5]" class="Input-text" type="text" id="fname_5"
                                   autocomplete="off" placeholder="Surname 5">
                        </div> -->

                        <!-- <div class="Input">
                            <label class="Input-label" for="fname_6">Name</label>
                            <input v-model="surnames[6]" class="Input-text" type="text" id="fname_6"
                                   autocomplete="off" placeholder="Surname 6">
                        </div>

                        <div class="Input">
                            <label class="Input-label" for="fname_7">Name</label>
                            <input v-model="surnames[7]" class="Input-text" type="text" id="fname_7"
                                   autocomplete="off" placeholder="Surname 7">
                        </div>

                        <div class="Input">
                            <label class="Input-label" for="fname_8">Name</label>
                            <input v-model="surnames[8]" class="Input-text" type="text" id="fname_8"
                                   autocomplete="off" placeholder="Surname 8">
                        </div> -->

                        <h3 class="page-subtitle">Email </h3>
                        <p class="under-subtitle less-bottom-padding">Please enter your email so that we can send you a
                            link to your report.</p>

                        <div class="Input">
                            <label class="Input-label" for="email">Email</label>
                            <input v-model="email" class="Input-text" type="text" id="email" name="email"
                                   placeholder="Your email..">
                        </div>
                    </div>

                    <div id="error-message"></div>

                    <div style="padding-bottom: 20px">
                        <!--                        <button class="pay-now-button" onclick="testWithoutPaying()"> test </button>-->
                        <vue-stripe-checkout
                            v-if="showStripeCheckout"
                            ref="checkoutRef"
                            :items="items"
                            :successUrl="successUrl"
                            :cancelUrl="cancelUrl"
                        ></vue-stripe-checkout>
                        <button class="pay-now-button"
                                id="checkout-button-sku_FC0YpOHpFOnOwI"
                                role="link"
                                type="submit"
                        >
                            {{buttonText}}
                        </button>

                        <div v-if="errors.length" style="padding-top: 5%;">
                            <b style="color: red" v-if="formErrors">Please correct the following error(s):</b>
                            <ul :style="{ 'list-style-type': formErrors ? 'circle' : 'none' }">
                                <li v-for="(error, idx) in errors" :style="{ 'color': 'red'}" :key="idx">{{ error }}</li>
                            </ul>
                        </div>

                        <!-- <p class="mt-3">Search up to 4 surnames for $40.<br>Note you will be redirected towards a payment page.<br> 
                        </p> -->
                        <div style="" class="text-center h4 mt-4">

                            <a target="_blank" href="https://www.keepandshare.com/doc4/132269/sample-report-dec11-2022-pdf-264k?da=y">Click Here to view a sample report</a>
                        </div>
                    </div>
                </form>
            </div>

        </div>

        <div class="page-split--right">
            <div class="page-background-section" style="background: url('/static/io/images/Blank_Ireland.svg') center no-repeat"></div>
        </div>
    </div>
</template>

<script>
    
    import api from './services/apiService';

    export default {
        name: 'SurnamesForm',
        components: {},
        data: function () {
            return {
                buttonText: 'Click here to analyse',
                stripeCheckout: false,
                errors: [],
                formErrors: false,
                surnames: [],
                email: null,
                items: [
                    // {sku: 'sku_F2chFP3pQBwt05', quantity: 1}   // default
                    {sku: process.env.VUE_APP_STRIPE_SURNAMES_LIVE_SKU, quantity: 1}   // live
                    // {sku: process.env.MIX_STRIPE_AUTOSOMAL_TEST_SKU, quantity: 1}  // test
                ],
                successUrl: this.$store.state.VUE_APP_API_PATH + "/surnames/report",
                cancelUrl: this.$store.state.VUE_APP_API_PATH + '/cancel',
            }
        },
        methods: {
            onSubmit() {
                this.buttonText = 'Processing...'
                this.checkForm();
                
            },
            finalize() {
                
                const data = new FormData();

                let slist = ''

                for (let i = 0; i < this.surnames.length; i++) {
                    if (this.surnames[i]) {
                        slist = slist + this.surnames[i] + ','
                        data.append('surnames[]', this.surnames[i]);
                    }
                }

                // let fullList = slist.trim().split(',')
                // fullList.pop()
                // console.log(fullList)
                // if(fullList.length > 4){
                //     this.errors.push('Please provide a valid surname.')
                //     return
                // }

                this.surnames ? data.append('surname', this.surnames) : false;
                data.append('email', this.email);
                data.append('form_type', 'surnames_report');
                
                api.post("/api/surnamesform", data).then(response => {
                    const report = response.data.report;
                    if (report !== null) {
                        this.successUrl = this.successUrl + '/' + report.id + '/' + report.file_name + '/' + report.email;
                        this.stripeCheckout = true;
                        setTimeout(() => {
                                setTimeout(() => {
                                    this.buttonText = 'Click here to analyse'
                                }, 1000)
                                //this.$router.push({ path: '/surnames/report/' + report.id + '/' + report.file_name + '/' + report.email})
                                this.$refs.checkoutRef.redirectToCheckout();
                            }, 1000)
                    }
                })
                .catch(err => {
                    this.buttonText = 'Click here to analyse'
                    if(err.response.status == 400){
                        // this.errors.push('Sorry, but there is not enough information in the file to generate a valid report.')
                    }
                })
            },
            
            validEmail: function (email) {
                const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            },
            checkForm() {
                this.formErrors = false;
                this.errors = [];

                if (!this.email) {
                    this.errors.push('Email required.');
                } else if (!this.validEmail(this.email)) {
                    this.errors.push('Valid email required.');
                }


                if(!this.surnames || this.surnames.length < 1){
                    this.errors.push('Please provide at least one surname.')
                }

                for (let index = 0; index < this.surnames.length; index++) {
                    const element = this.surnames[index];
                    if(element && element.indexOf(',') > -1){
                        this.errors.push('Please provide a valid surname.')
                        break;
                    }
                    
                }

                if(this.surnames && this.surnames.length > 4){
                    this.errors.push('Please provide a valid surname.')
                }


                if (!this.errors.length) {

                    this.finalize();
                    
                }
                else{
                    this.formErrors = true;
                }

                
            },
        },
        computed: {
            showStripeCheckout() {
                return this.stripeCheckout;
            }
        }
    }
</script>