<template>
    <div id="" class="container">
        <div id="preloader" v-if="!showTemplate && !errorMessage" style="min-height: 500px;">
            <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
            <div class="vld-overlay is-active is-full-page preloader-text">
                <span>We are now generating your report. Please wait, this may take several minutes to complete.</span>
            </div>

             
        </div>

        <div v-if="errorMessage" style="margin: auto; width: 80%; margin-top: 20%; font-size: 20px;">
            <p>Sorry, something went wrong during the generation of your report.</p>
            <p>Please email tyronebowes@gmail.com</p>
            <p>{{errorMessage}}</p>
            <p>and we will get back to you as soon as possible.</p>
        </div>

       

        <div id="autosomal-report" class="container" v-if="showTemplate">
            <div class="row">
                <div class="col">
                    <h2>IRISH ORIGENES SURNAMES REPORT</h2>

                    <hr>
                </div>
            </div>

            <div class="row" v-if="showSurnames">
                <div class="col">

                    <div class="row">
                        <div class="col pt-3 pb-3">
                            Below are Irish counties linked to the surnames you entered.
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">

                            <table class="table table-bordered">
                                <thead>
                                    <tr class="fw-bold">
                                        <th>Surname</th>
                                        <th>Main Counties</th>
                                        
                                    </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(entry, idx) in nameOrigins" :key="idx">
                                    <td>
                                        {{entry.original}}
                                    </td>
                                    <td>
                                        {{entry.mainCounty}}
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>

                </div>
            </div>

            <div class="row mt-4" v-if="notAssociatedWithIreland">
                <div class="col text-danger">
                    <p>Not Associated with Medieval Ireland? Your Surnames are of possible Scots Irish or Scottish origin! Search the 
                        Scots Irish and Scottish Surnames Maps for FREE click <a href="https://www.origenesmaps.com" target="_blank">www.origenesmaps.com</a> or contact Irish Origenes <a href="mailto:tyronebowes@gmail.com">CLICK HERE</a>
                    </p>
                </div>
            </div>


            
            <div v-if="showSurnamesMap" class="row">
                <div class="col pt-4 pb-2">
                    <h3>Pinpointed Origin of Each of Your Ancestral Surnames</h3>
                    <div>Place cursor over each dot for more information</div>
                </div>
              
            </div>

            <div class="row">
                <div class="col">
                    <div class="mb-6">
                        <surnames-map 
                            v-if="showSurnamesMap"
                            :surnamesdata="geoChartSurnamesData"
                        ></surnames-map>

                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col pt-2">

                    <div style="">Explore Your Ancestral Surnames as they appear on the Irish Origenes Surnames Map for FREE</div>
                    <div>Click  <a href="https://www.origenesmaps.com" target="_blank">www.origenesmaps.com</a></div>
                    
                </div>
            </div>
            

            <div class="row">
                <div class="col">

                    <features-table v-if="showSurnamesMap" error="Unfortunately we could not find any locations related to the provided surnames" :features="geoChartSurnamesData" title="Origin of Ancestral Surname" split=":"></features-table>
                    
                </div>
            </div>

            <div class="row">
                <div class="col pt-4 pb-2">
                    <h3>Castles Related To Your Surnames</h3>
                    <div>Place cursor over each dot for more information</div>
                </div>
              
            </div>

            <div class="row">
                <div class="col">

                    <features-map v-if="castles && castles.length > 1" :features="castles" title="Castles Map"></features-map>
                </div>
            </div>

            <div class="row">
                <div class="col pt-2">

                    <div style="">Explore Each Castle as it appears on the Irish Origenes Castles Map for FREE</div>
                    <div>Click  <a href="https://www.origenesmaps.com" target="_blank">www.origenesmaps.com</a></div>
                    
                    <div class="pt-3">The Castles of Ireland were used to reconstruct the first ever Clan Territories of Ireland map</div>
                    <div>Explore online for FREE <a href="https://www.origenesmaps.com/maps/clan-territories-ireland" target="_blank">www.origenesmaps.com/maps/clan-territories-ireland</a></div>
                    
                </div>
            </div>
            
            
            
            <div class="row">
                <div class="col">

                    <features-table v-if="castles" error="Unfortunately we could not find any castles related to the provided surnames" :features="castles" title="Castles Related to Surname"></features-table>
                    
                </div>
            </div>

            <div class="row">
                <div class="col pt-4 pb-2">
                    <h3>Placenames and Townlands Associated to Your Surnames</h3>
                    <div>Place cursor over each dot for more information</div>
                </div>
              
            </div>

            <div class="row">
                <div class="col">

                    <features-map v-if="places && places.length > 1" :features="places" title="Places Map"></features-map>
                </div>
            </div>
            
            <div class="row">
                <div class="col pt-2">

                    <div style="">Explore these locations online </div>
                    <div>Click  <a href="https://webapps.geohive.ie/mapviewer/index.html" target="_blank">https://webapps.geohive.ie/mapviewer/index.html</a></div>
                    
                </div>
            </div>
            
            

            <div class="row">
                <div class="col">

                    <features-table v-if="places" :features="places" title="Placename or Townland associated with Surname" error="Unfortunately we could not find any placenames or townlands associated with the provided surnames"></features-table>
                </div>
            </div>

            
            

           
            
            
         </div>
    </div>
</template>

<script>
    import api from './services/apiService';
    import SurnamesMap from './reports/SurnamesMap.vue';
    import { GChart } from 'vue-google-charts'
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import FeaturesTable from './FeaturesTable.vue';
    import FeaturesMap from './FeaturesMap.vue';

    export default {

        name: 'SurnamesReport',

        components: {
            SurnamesMap,
            GChart,
            Loading,
            FeaturesTable,
            FeaturesMap
        },

        data: function () {
            return {
                notAssociatedWithIreland: false,
                reportReady: false,
                errorMessage: null,
                isLoading: true,
                fullPage: true,
                chartSurnamesData: [],
                castlesOptions: {
                    region: 'IE',
                    // width: 'auto',
                    height: window.innerWidth < 400 ? '380' : '600',
                    resolution: 'provinces',
                    displayMode: 'marker',
                    enableRegionInteractivity: true,
                    chart: {
                      title: 'Castles'
                    },
                    colorAxis: {colors: ['orange', 'blue', 'yellow', 'lightblue', 'brown', 'red', 'grey', 'green']},
                    sizeAxis: {
                        minSize: 7,
                        maxSize: 7
                    }
                },
                nameOrigins: [],
                featuresData: []
            }
        },
        


        mounted() {
            this.getReportData()
        },

        methods: {
          
            parseFeaturesData(data){
              console.log(data)
              let features = []
            //   let castles_total = data.castles.length
            //   let placenames_total = data.name_features

              data.name_features.forEach(f => {
                
                let castles_total = data.name_features.filter(cf => cf.feature_type === 'castle').length
                let placenames_total = data.name_features.filter(cf => cf.feature_type === 'placename').length
                let townlands_total = data.name_features.filter(cf => cf.feature_type === 'townland').length
                  let x = {...f}
                  x.castles_total = castles_total
                  x.placenames_total = placenames_total
                  x.townlands_total = townlands_total
                  x.places_total = placenames_total + townlands_total
                  
                features.push(x)
              })
              
              return features
            },
            

            parseMapNamesData(data){

                let chartNamesData = []
                
                for (const name in data.surname_origins){
                    let nm = data.surname_origins[name]
                    
                    nm.locations.forEach(loc => {
                        let details = loc.county
                        if(loc.town){
                            details = loc.town + ", " + details
                        }
                        
                        chartNamesData.push([
                            Number(loc.lat),
                            Number(loc.lng),
                            nm.original + ' : ' + details,
                            nm.locations.length
                        ])
                    })
                    
                    
                }

                return chartNamesData

            },

            parseCounties(surnameOrigins){
                let counties = {}

                surnameOrigins.locations.forEach(loc => {
                    if(counties[loc.county]){
                        counties[loc.county] = counties[loc.county] + 1
                    }
                    else{
                        counties[loc.county] = 1
                    }
                    
                })

                return counties
            },

            determineMainCounty(counties){
                let max = 0
                let count = ''
                for(const co in counties){
                    if(counties[co] > max){
                        max = counties[co]
                        count = co
                    }
                    else if(counties[co] == max){
                        count = count + ', ' + co
                    }
                }

                if(count == ''){
                    count = 'Surname not associated with medieval Ireland'
                    this.notAssociatedWithIreland = true
                }

                return count
            },

            parseNameOrigins(data){

                var nameOrigins = []
                for (const name in data.surname_origins){
                    
                    let details = data.surname_origins[name]
                    let counties = this.parseCounties(details)
                    let count = this.determineMainCounty(counties)

                    nameOrigins.push({
                        name: details.name,
                        original: details.original,
                        mainCounty: count,
                       
                    })
                }

                return nameOrigins

            },

            processDataResponse(data){
                /*
                Example data response:
                {
                    "surname_origins": 
                        {   "Fegan": 
                                {"name": "Fegan", "original": "Fegan", "locations": 
                                    [
                                        {"county": "Down", "town": "Newry", "lat": "54.1891138137281", "lng": "-6.23830062615527"}]
                                },
                            "Doherty": 
                                {"name": "Doherty", "original": "Doherty", "locations": 
                                    [
                                        {"county": "Donegal", "town": "Coolcross", "lat": "55.2679142566427", "lng": "-7.37332359540872"}, 
                                        {"county": "Donegal", "town": "Carn Middle", "lat": "54.7829240712846", "lng": "-7.73734758330296"}
                                    ]
                                }
                        }
                */

                console.log(data)
                    
                this.nameOrigins = this.parseNameOrigins(data);
                this.chartSurnamesData = this.parseMapNamesData(data)
                this.featuresData = this.parseFeaturesData(data)
                this.reportReady = true
            },

            getReportData(){
                let params = this.$route.params;
                let link = '/api/surnames/report?id=' + params.id + '&code=' + params.code + '&email=' + params.email;

                api.get(link).then(response => {
                    
                    this.processDataResponse(response.data)
                    // var nameOrigins = self.parseNameOrigins(data)
                    // this.nameOrigins = nameOrigins;
                   
                    // var mapNamesData = self.parseMapNamesData(data)
                    
                    // // surname features data e.g. castles, townlands, placenames
                    // if(data.name_features){
                    //   self.featuresData = self.parseFeaturesData(data.name_features)
                    // }
                    
                    // //console.log(mapNamesData)

                    // this.chartSurnamesData = [...this.chartSurnamesData, ...mapNamesData];
                    
                })
                .catch(err => {
                    console.log(err)
                    this.errorMessage = 'Quoting report id: ' + params.id + ' and ref: ' + params.code
                })

            }
        },

        computed: {
            
            geoChartSurnamesData() {
              
                return this.chartSurnamesData;
            },
            showSurnames() {
                return this.nameOrigins.length > 0;
            },
            showSurnamesMap() {
                return this.chartSurnamesData.length > 1;
            },
            showTemplate() {
                return this.reportReady
            },
            castles(){
              let c = this.featuresData
                .filter(x => x.feature_type === 'castle')
                
              
              let castles =  c.map(cas => {
                  return [ Number(cas.c_y), Number(cas.c_x), cas.family_name + ' - ' + cas.feature_name, cas.castles_total]
                })
              castles.unshift(['LATITUDE', 'LONGITUDE', 'Details', 'Total Castles Found'])
              console.log('castles')
              console.log(castles)
              return castles
            },
            placenames(){
              let c = this.featuresData
                .filter(x => x.feature_type === 'placename')
                
              
              let placenames =  c.map(cas => {
                  return [ Number(cas.c_y), Number(cas.c_x), cas.family_name + ' - ' + cas.feature_name, cas.places_total]
                })
              //placenames.unshift(['LATITUDE', 'LONGITUDE', 'Details', 'Total Places For Name'])
              
              return placenames
            },
            townlands(){
              let c = this.featuresData
                .filter(x => x.feature_type === 'townland')
                
              
              let townlands =  c.map(cas => {
                  return [ Number(cas.c_y), Number(cas.c_x), cas.family_name + ' - ' + cas.feature_name, cas.places_total]
                })
              townlands.unshift(['LATITUDE', 'LONGITUDE', 'Details', 'Total Places Found'])
              
              return townlands
            },

            places(){
                return this.townlands.concat(this.placenames) 
            }
        }
    }
</script>
