<template>
    <div id="template-ydna-report">
        <div id="preloader" v-if="!showTemplate">
            <loading :active.sync="isLoading" :is-full-page="fullPage" style="min-height: 500px;"></loading>
            <div class="vld-overlay is-active is-full-page preloader-text">
                <span>We are now analysing your report. Please wait up to 2 minutes for this analysis to complete.</span>
            </div>
        </div>
        
        <div v-if="showTemplate" style="width: 80%; margin: auto; padding-bottom: 15%;">

            <div v-if="showM222">

                <h1 class="page-title">Thank you.</h1>
                <div>
                    <p>We have pinpointed your Irish origin. Please click the link below to download your report:</p>
                    <p><a :href="m222Url" style="font-size: 20pt">Click here to download your Irish Origenes Report</a></p>

                </div>
                
            </div>

            <div v-if="showStandard">

                <div class="sharethis-inline-share-buttons"></div>

                <h1 class="page-title"> Your Predicted Irish Origin </h1>

                <p> We have pinpointed your Irish origin to be the <span id="familyName" v-text="familyName"></span> family
                    from <span id="areaName" v-text="areaName"></span>. They are marked by the large blue point on the map.
                    <br>Feel free to share this report with your friends and family using the share buttons above or just
                    copy and paste the link. </p>

                <p style="color:gray; padding: 0 2%;">Due to the variability in the levels that people test to, and the
                    difference in genetic distance visible at each level, <br> we highly recommend that for optimum results
                    you analyse the 111, 67 and 37 match files for each test subject. Due to the agrarian nature of Irish
                    society, the area we identify is also the area that your paternal ancestral paper-trail will lead back
                    to! </p>

                <div class="chart-container" >
                    <!--            <div id="donutchart" style="width: 100%; height:  500px;"></div>-->
                    <GChart
                        id="donutchart"
                        style="width: 100%; height:  500px;"
                        type="PieChart"
                        :data="pieChartData"
                        :options="pieChartOptions"
                    />
                    
                        <GChart
                            v-if="geoChart.length > 0"
                            id="chart_div"
                            style="width: 100%; height: 100%;"
                            type="GeoChart"
                            :data="geoChartData"
                            :options="geoChartOptions"
                            :settings="{ packages: ['corechart', 'table', 'map', 'geochart']}"
                        />
                    
                    <span v-if="geoChart.length == 0">
                        <p style="margin-top: 80px;">Unfortunately there was not enough information in the file provided to pinpoint an origin location.</p>
                    </span>
                    <!--            <div id="chart_div" style="width: 100%; height: 100%;"></div>-->
                </div>

            </div>
            
        </div>
    </div>
</template>

<script>
    import api from '../components/services/apiService';
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import {GChart} from 'vue-google-charts';

    export default {
        name: 'report-component',
        components: {GChart, Loading},
        data: function () {
            return {
                isLoading: true,
                fullPage: true,
                pieChart: [
                    ['Name', 'Count'],
                ],
                pieChartOptions: {
                    title: 'Top 10 Second Names',
                    pieHole: 0.4,
                    is3D: true,
                    width: 'auto',
                    height: '500px'
                },
                geoChart: [
                    ['LATITUDE', 'LONGITUDE', 'DESCRIPTION', 'Amount'],
                ],
                geoChartOptions: {
                    region: 'IE',
                    width: 'auto',
                    height: 'auto',
                    resolution: 'provinces',
                    displayMode: 'markers',
                    enableRegionInteractivity: true,
                    colorAxis: {colors: ['green', 'blue'],},
                    mapsApiKey: process.env.VUE_APP_GOOGLE_CHARTS_MAPS_API_KEY
                },
                name: '',
                area: '',
                showStandard: false,
                showM222: false,
                m222Url: '',
                ready: false
            }
        },
        mounted() {
            let params = this.$route.params;
            let link = '/api/ydna/view?id=' + params.id + '&email=' + params.email;

            api.get(link).then(response => {
                

                if(response.data.hasM222){
                    this.showM222 = true;
                    this.m222Url = response.data.murl;
                    
                }
                else{

                    this.pieChart = [...this.pieChart, ...response.data.pieChart];
                    if(response.data.geoChart.length > 0){

                        this.geoChart = [...this.geoChart, ...response.data.geoChart];
                    }
                    else{
                        this.geoChart = []
                        
                    }
                    this.name = response.data.familyName;
                    this.area = response.data.areaName;

                    this.showStandard = true
                }
                this.ready = true;
            });
        },
        computed: {
            pieChartData() {
                return this.pieChart;
            },
            geoChartData() {
                return this.geoChart;
            },
            familyName() {
                return this.name;
            },
            areaName() {
                return this.area;
            },
            showTemplate() {
                //return this.pieChart.length > 1;
                return this.ready;
            },
        }
    }
</script>
