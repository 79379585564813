<template>

    <div class="container">
        
        <div >
                
            <GChart v-if="features"
            :settings="{ packages: ['corechart', 'table', 'map', 'geochart']}"
            id="chart_castles"
            type="GeoChart"
            :data="features"
            :options="chartOptions"
            :resizeDebounce="200"

            />

        </div>
    </div>

</template>

<script>

import { GChart } from 'vue-google-charts'

export default {
    name: 'FeaturesMap',

    components: {
        GChart
    },

    props: {
        title: String,
        features: Array,
        options: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },

    data(){
        return {
            chartOptions: {
                region: 'IE',
                // width: 'auto',
                height: window.innerWidth < 400 ? '380' : '600',
                resolution: 'provinces',
                displayMode: 'marker',
                enableRegionInteractivity: true,
                chart: {
                    title: 'Castles'
                },
                colorAxis: {colors: ['orange', 'blue', 'yellow', 'lightblue', 'brown', 'red', 'grey', 'green']},
                sizeAxis: {
                    minSize: 7,
                    maxSize: 7
                },
                ...this.options
            }
        }
    },

    methods: {

    }
}
</script>