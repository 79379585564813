<template>
    <div class="page-split contact-page">
        <div class="page-split--left">
            <div class="upload-container">
                <div>
                    <h1 class="page-title">Discover your Irish Origin.</h1>
                    <p class="">Use your Y-DNA results to Pinpoint where your ancestor lived when surnames appeared in
                        Ireland. </p>
                </div>

                <form v-on:submit.prevent="onSubmit">
                   

                    <div>
                        <h3 class="page-subtitle"> Step 1 : Upload CSV </h3>
                        <p class="under-subtitle">Upload your 111, 67 OR 37 csv match file (the csv file can be saved
                            from
                            your FTDNA Y-DNA results <a href="https://www.familytreedna.com/sign-in">click here</a>).
                        </p>
                        <vue-dropzone ref="myVueDropzone" id="onlyUpload" :options="dropzoneOptions"
                                      :include-styling="false" class="dropzone dz-clickable"
                                      @vdropzone-file-added="fileAdded"></vue-dropzone>
                    </div>

                    <div>
                        <h3 class="page-subtitle"> Step 2 : Family History </h3>
                        <p class="under-subtitle less-bottom-padding">Enter the surname of the male relative who took
                            the
                            Y-DNA test. (If test subject was adopted then leave blank)</p>

                        <div class="Input">
                            <label class="Input-label" for="fname">Name</label>
                            <input v-model="surname" class="Input-text" type="text" id="fname" name="name"
                                   placeholder="Surname..">
                        </div>

                        <h3 class="page-subtitle"> Step 3 : Email </h3>
                        <p class="under-subtitle less-bottom-padding">Please enter your email so that we can send you a
                            link
                            to your report.</p>

                        <div class="Input">
                            <label class="Input-label" for="email">Email</label>
                            <input v-model="email" class="Input-text" type="text" id="email" name="email"
                                   placeholder="Your email..">
                        </div>
                    </div>

                    <div id="error-message"></div>

                    <div style="padding-bottom: 20px">
                        <!--                        <button class="pay-now-button" onclick="testWithoutPaying()"> test </button>-->
                        <vue-stripe-checkout
                            v-if="showStripeCheckout"
                            ref="checkoutRef"
                            :items="items"
                            :successUrl="successUrl"
                            :cancelUrl="cancelUrl"
                        ></vue-stripe-checkout>
                        <button :class="{'pay-now-button' : true, 'pay-now-button-disabled' : disableButton}"
                                id="checkout-button-sku_FC0YpOHpFOnOwI"
                                role="link"
                                type="submit"
                                :disabled="disableButton"
                                
                        >
                            {{buttonText}}
                        </button>

                         <div v-if="errors.length" style="padding-top: 5%;">
                            <b style="color: red" v-if="formErrors">Please correct the following error(s):</b>
                            <ul :style="{ 'list-style-type': formErrors ? 'circle' : 'none' }">
                                <li v-for="(error, idx) in errors" style="color: red" :key="idx">{{ error }}</li>
                            </ul>
                        </div>

                        <p>If successful you will be redirected towards a payment page.<br><br> The price is only $14.99.
                        </p>
                    </div>
                </form>
            </div>

        </div>

        <div class="page-split--right">
            <div class="page-background-section"
                 style="background: url('/static/io/images/Blank_Ireland.svg') center no-repeat"></div>
        </div>
    </div>
</template>

<script>
    import vueDropzone from 'vue2-dropzone';
    import 'vue2-dropzone/dist/vue2Dropzone.min.css';
    import api from '../components/services/apiService';

    export default {
        name: 'upload-component',
        components: {vueDropzone},
        data: function () {
            return {
                buttonText: 'Click here to analyse',
                file: null,
                stripeCheckout: false,
                errors: [],
                formErrors: false,
                surname: null,
                email: null,
                disableButton: false,
                dropzoneOptions: {
                    url: '/',
                    maxFilesize: 3,
                    maxFiles: 1,
                    acceptedFiles: ".csv",
                    autoProcessQueue: false,
                },
                items: [
                    // {sku: 'sku_F2chFP3pQBwt05', quantity: 1} // default
                    {sku: process.env.VUE_APP_STRIPE_YDNA_LIVE_SKU, quantity: 1}  // live
                    // {sku: process.env.MIX_STRIPE_YDNA_TEST_SKU, quantity: 1}   // test
                ],
                successUrl: process.env.VUE_APP_API_PATH + "/report",
                cancelUrl: process.env.VUE_APP_API_PATH + '/cancel',
            }
        },
        methods: {
            onSubmit() {
                this.buttonText = "Please wait..."
                this.disableButton = true;
                this.checkForm();
            },
            finalize() {
                const data = new FormData();
                data.append('file', this.file);
                if(!this.surname){
                    this.surname = ''
                }
                data.append('surname', this.surname);
                data.append('form_type', 'ydna')
                data.append('email', this.email);
                const parameters = {headers: {'Content-Type': 'multipart/form-data'}};
                api.post("/api/ydna/generate", data, parameters).then(response => {
                    const report = response.data.report;
                    if (report !== null) {
                        this.successUrl = this.successUrl + '/' + report.id + '/' + report.email;
                         this.stripeCheckout = true;
                        setTimeout(() => {
                            this.$refs.checkoutRef.redirectToCheckout();
                            //this.$router.push({ path: '/report/' + report.id + '/' + report.email})
                        }, 2000)
                        //this.$router.push({ path: '/report/' + report.id + '/' + report.email})

                        // api.post('/api/validate', { id: report.id, email: report.email }).then(valResp => {
                            
                        //     if(valResp.data.valid){
                        //         this.stripeCheckout = true;
                        //         setTimeout(() => {
                        //             //this.$refs.checkoutRef.redirectToCheckout();
                        //             this.$router.push({ path: '/report/' + report.id + '/' + report.email})
                        //         }, 2000)
                        //     }
                        //     else{
                                
                        //         this.errors.push('Unfortunately we cannot generate a report from this file.')
                        //         this.disableButton = false;
                        //         this.buttonText = 'Click here to analyse'
                        //     }
                            

                        // })
                        // .catch(err => {
                            
                        //     this.errors.push('Unfortunately we cannot generate a report from this file.')
                        //     this.disableButton = false;
                        //     this.buttonText = 'Click here to analyse'
                        // })
                        
                    }
                })
                .catch(err => {
                    this.errors.push('Unfortunately we cannot generate a report from this file.')
                    this.disableButton = false;
                    this.buttonText = 'Click here to analyse'
                })
            },
            fileAdded(file) {
                this.file = file;
            },
            validEmail: function (email) {
                const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            },
            checkForm() {
                this.errors = [];
                this.formErrors = false;

                if (!this.email) {
                    this.errors.push('Email is required.');
                } else if (!this.validEmail(this.email)) {
                    this.errors.push('A valid email is required.');
                }

                if (!this.file) {
                    this.errors.push('A CSV file is required.');
                } else if (this.file.name.lastIndexOf('.csv') === this.file.name.length - 4 && this.file.name.substr(this.file.name.lastIndexOf('.') + 1) !== 'csv') {
                    this.errors.push('The file must be in csv format.');
                }

                if(this.file.type != 'text/csv' && this.file.type != 'text/plain' && this.file.type != 'application/vnd.ms-excel'){
                    this.errors.push('Sorry, the submitted file is not of the correct type.');
                }

                if (!this.errors.length) {
                    // const data = new FormData();
                    // data.append('file', this.file);
                    // this.surname ? data.append('surname', this.surname) : false;
                    // data.append('email', this.email);
                    // data.append('form_type', 'ydna')
                    // const parameters = {headers: {'Content-Type': 'multipart/form-data'}};
                    // api.post("/api/file-check-ydna", data, parameters).then(response => {
                    //     let check = response.data.check;
                    //     if(check && check == true) {
                    //         this.finalize();
                    //     }else{
                    //         this.errors.push('Sorry, the file provided is invalid.');
                    //     }
                        
                    // })
                }
                else{
                    this.disableButton = false;
                    this.buttonText = 'Click here to analyse'
                    this.formErrors = true;
                }

                if (!this.errors.length) {
                    this.finalize();
                }
            },
        },
        computed: {
            showStripeCheckout() {
                return this.stripeCheckout;
            }
        }
    }
</script>


<style scoped>
    .pay-now-button-disabled{
        background-color: #aaa;
    }
</style>