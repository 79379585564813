<template>
    <div class="page-split contact-page">
        <div class="page-split--left">
            <div class="upload-container">
                <div>
                    <h1 class="page-title">Discover your Irish Origin.</h1>
                    <p class="under-subtitle">Use your Autosomal DNA results to identify DNA hotspots within Ireland and then link each Irish 
                        ancestral surname with a DNA revealed location</p>
                </div>

                <form v-on:submit.prevent="onSubmit">
                    

                    <div>
                        <h3 class="page-subtitle"> Step 1 : Upload CSV </h3>
                        <p class="under-subtitle"><p class="under-subtitle">Upload your Autosomal CSV matches file. That file can be accessed via your 
                            FTDNA or 23andMe results. <a href="/how-it-works">(Click here for video tutorial)</a>
                        </p>
                        <vue-dropzone ref="myVueDropzone" id="onlyUpload" :options="dropzoneOptions"
                                      :include-styling="false" class="dropzone dz-clickable"
                                      @vdropzone-file-added="fileAdded"></vue-dropzone>
                    </div>

                    <div>
                        <h3 class="page-subtitle"> Step 2 : Family History </h3>
                        <p class="under-subtitle less-bottom-padding">Enter up to 8 Irish ancestral surnames</p>

                        <div class="Input">
                            <label class="Input-label" for="fname_1">Name</label>
                            <input v-model="surnames[1]" class="Input-text" type="text" id="fname_1"
                                   autocomplete="off" placeholder="Surname 1">
                        </div>

                        <div class="Input">
                            <label class="Input-label" for="fname_2">Name</label>
                            <input v-model="surnames[2]" class="Input-text" type="text" id="fname_2"
                                   autocomplete="off" placeholder="Surname 2">
                        </div>

                        <div class="Input">
                            <label class="Input-label" for="fname_3">Name</label>
                            <input v-model="surnames[3]" class="Input-text" type="text" id="fname_3"
                                   autocomplete="off" placeholder="Surname 3">
                        </div>

                        <div class="Input">
                            <label class="Input-label" for="fname_4">Name</label>
                            <input v-model="surnames[4]" class="Input-text" type="text" id="fname_4"
                                   autocomplete="off" placeholder="Surname 4">
                        </div>

                        <div class="Input">
                            <label class="Input-label" for="fname_5">Name</label>
                            <input v-model="surnames[5]" class="Input-text" type="text" id="fname_5"
                                   autocomplete="off" placeholder="Surname 5">
                        </div>

                        <div class="Input">
                            <label class="Input-label" for="fname_6">Name</label>
                            <input v-model="surnames[6]" class="Input-text" type="text" id="fname_6"
                                   autocomplete="off" placeholder="Surname 6">
                        </div>

                        <div class="Input">
                            <label class="Input-label" for="fname_7">Name</label>
                            <input v-model="surnames[7]" class="Input-text" type="text" id="fname_7"
                                   autocomplete="off" placeholder="Surname 7">
                        </div>

                        <div class="Input">
                            <label class="Input-label" for="fname_8">Name</label>
                            <input v-model="surnames[8]" class="Input-text" type="text" id="fname_8"
                                   autocomplete="off" placeholder="Surname 8">
                        </div>

                        <h3 class="page-subtitle"> Step 3 : Email </h3>
                        <p class="under-subtitle less-bottom-padding">Please enter your email so that we can send you a
                            link to your report.</p>

                        <div class="Input">
                            <label class="Input-label" for="email">Email</label>
                            <input v-model="email" class="Input-text" type="text" id="email" name="email"
                                   placeholder="Your email..">
                        </div>
                    </div>

                    <div id="error-message"></div>

                    <div style="padding-bottom: 20px">
                        <!--                        <button class="pay-now-button" onclick="testWithoutPaying()"> test </button>-->
                        <vue-stripe-checkout
                            v-if="showStripeCheckout"
                            ref="checkoutRef"
                            :items="items"
                            :successUrl="successUrl"
                            :cancelUrl="cancelUrl"
                        ></vue-stripe-checkout>
                        <button class="pay-now-button"
                                id="checkout-button-sku_FC0YpOHpFOnOwI"
                                role="link"
                                type="submit"
                        >
                            Click here to analyse
                        </button>

                        <div v-if="errors.length" style="padding-top: 5%;">
                            <b style="color: red" v-if="formErrors">Please correct the following error(s):</b>
                            <ul :style="{ 'list-style-type': formErrors ? 'circle' : 'none' }">
                                <li v-for="(error, idx) in errors" :style="{ 'color': 'red'}" :key="idx">{{ error }}</li>
                            </ul>
                        </div>

                        <p>If successful you will be redirected towards a payment page.<br> An automated report costs $34.99
                        </p>
                    </div>
                </form>
            </div>

        </div>

        <div class="page-split--right">
            <div class="page-background-section" style="background: url('/static/io/images/Blank_Ireland.svg') center no-repeat"></div>
        </div>
    </div>
</template>

<script>
    import vueDropzone from 'vue2-dropzone';
    import 'vue2-dropzone/dist/vue2Dropzone.min.css';
    import api from './services/apiService';

    export default {
        name: 'autosomal-component-v2',
        components: {vueDropzone},
        data: function () {
            return {
                stripeCheckout: false,
                errors: [],
                formErrors: false,
                surnames: [],
                email: null,
                file: null,

                dropzoneOptions: {
                    url: '/',
                    maxFilesize: 3,
                    maxFiles: 1,
                    addRemoveLinks: true,
                    acceptedFiles: ".csv",
                    autoProcessQueue: false,
                    dictDefaultMessage: "<span class='m-dropzone__msg-title text-center text-bold'>Drop file here to upload</span>"
                },
                items: [
                    // {sku: 'sku_F2chFP3pQBwt05', quantity: 1}   // default
                    {sku: process.env.VUE_APP_STRIPE_AUTOSOMAL_LIVE_SKU, quantity: 1}   // live
                    // {sku: process.env.MIX_STRIPE_AUTOSOMAL_TEST_SKU, quantity: 1}  // test
                ],
                successUrl: this.$store.state.VUE_APP_API_PATH + "/v2/autosomal/report",
                cancelUrl: this.$store.state.VUE_APP_API_PATH + '/cancel',
            }
        },
        methods: {
            onSubmit() {
                this.checkForm();
            },
            finalize() {
                const data = new FormData();
                data.append('file', this.file);

                for (let i = 0; i < this.surnames.length; i++) {
                    if (this.surnames[i] !== '') {
                        data.append('surnames[]', this.surnames[i]);
                    }
                }

                this.surnames ? data.append('surname', this.surnames) : false;
                data.append('email', this.email);
                data.append('form_type', 'autosomal_v2');
                const parameters = {
                    headers: {'Content-Type': 'multipart/form-data'}
                };
                api.post("/api/file-upload", data, parameters).then(response => {
                    const report = response.data.report;
                    if (report !== null) {
                        this.successUrl = this.successUrl + '/' + report.id + '/' + report.file_name + '/' + report.email;
                        this.stripeCheckout = true;
                        setTimeout(() => {
                                //this.$router.push({ path: '/v2/autosomal/report/' + report.id + '/' + report.file_name + '/' + report.email})
                                this.$refs.checkoutRef.redirectToCheckout();
                            }, 1000)
                    }
                })
                .catch(err => {
                    
                    if(err.response.status == 400){
                        this.errors.push('Sorry, but there is not enough information in the file to generate a valid report.')
                    }
                })
            },
            fileAdded(file) {
                this.file = file;
            },
            validEmail: function (email) {
                const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            },
            checkForm() {
                this.formErrors = false;
                this.errors = [];

                if (!this.email) {
                    this.errors.push('Email required.');
                } else if (!this.validEmail(this.email)) {
                    this.errors.push('Valid email required.');
                }

                if (!this.file) {
                    this.errors.push('CSV file required.');
                } else if (this.file.name.lastIndexOf('.csv') === this.file.name.length - 4 && this.file.name.substr(this.file.name.lastIndexOf('.') + 1) !== 'csv') {
                    this.errors.push('File must be in csv format.');
                }

                if(!this.surnames || this.surnames.length < 1){
                    this.errors.push('Please provide at least one surname.')
                }

                if (!this.errors.length) {
                    console.log(this.file.type)
                    if(this.file.type != 'text/csv' && this.file.type != 'text/plain' && this.file.type != 'application/vnd.ms-excel'){
                        this.errors.push('Sorry, the submitted file is not of the correct type.');
                    }
                    else{
                        if(this.file.size < 300000){
                            this.errors.push('Sorry, but there is not enough information in the file to generate a valid report.')
                        }
                        else{
                            this.finalize();
                        }
                    }
                    // if(this.file.size >= 300000){

                    // }
                    // else{

                    // }
                    // const data = new FormData();
                    // data.append('file', this.file);
                    // const parameters = {headers: {'Content-Type': 'multipart/form-data'}};
                    // api.post("/api/file-check-autosomal", data, parameters).then(response => {
                    //     let check = response.data.check;
                    //     let len = response.data.length;
                    //     if(check && check == true) {    

                    //         if(len){
                    //             this.finalize();
                    //         }
                    //         else{
                    //             this.errors.push('Sorry, but there is not enough information in the file to generate a valid report.')
                    //         }
                    //     }else{
                    //         this.errors.push('Sorry, the submitted file is not of the correct type.');
                    //     }
                        
                    // })
                }
                else{
                    this.formErrors = true;
                }

                // if (!this.errors.length) {
                //     this.finalize();
                // }
            },
        },
        computed: {
            showStripeCheckout() {
                return this.stripeCheckout;
            }
        }
    }
</script>