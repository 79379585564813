<template>
  <div class="page-split home-page" style="min-height: 750px;">
        <div class="relative">
            <div class="page-split--left">
                <div class="text-block" style="padding: 5%; padding-top: 20%;">
                    <div >
                        <h2>Welcome to <span class="green">Irish Origenes</span> Automated Analysis and Reporting</h2>
                        <p> Choose DNA test type (Autosomal or Y-DNA).
                            No DNA test results? Not a problem! Simply search for your Irish Surnames.</p>
                    </div>
                    <div class="progress-bar-container">
                        <div class="progress-bar">
                            <div class="progress-bar__circles">

                                <div class="progress-bar__circle progress-bar__circle--completed">
                                    <span class="number">&#10003;</span>
                                    <span class="progress-bar__circle__label">DNA Testing</span>
                                </div>

                                <div class="progress-bar__bar progress-bar__bar--completed"></div>

                                <div class="progress-bar__circle progress-bar__circle--completed">
                                    <span class="number">2</span>
                                    <span class="progress-bar__circle__label">Upload CSV</span>
                                </div>

                                <div class="progress-bar__bar progress-bar__bar--completed"></div>

                                <div class="progress-bar__circle progress-bar__circle--selected">
                                    <span class="number">3</span>
                                    <span class="progress-bar__circle__label">View Report</span>
                                </div>

                            </div>
                        </div>
                    </div>
                    <a class="button" href="/v2/autosomal"> Autosomal </a>
                    <a class="button" href="upload"> Y-DNA </a>
                    <a class="button" href="surnames"> Irish Surname Search </a>
                </div>
            </div>

            <div class="page-split--right"><div class="page-background-section"></div></div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src


export default {
  name: 'Home',
  components: {
    
  }
}
</script>

<style scoped>
.page-background-section {
  height: 100%;
  background-image: url(/static/io/images/index.jpeg?008e147b9bcbb36a3fc2d96d5184bfe0);
  background-size: cover !important;
}
</style>
