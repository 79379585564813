<template>
    <div id="template-autosomal-report">
        <div id="preloader" v-if="!showTemplate && !errorMessage" style="min-height: 500px;">
            <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
            <div class="vld-overlay is-active is-full-page preloader-text">
                <span>We are now analysing your report. Please wait, this may take several minutes to complete.</span>
            </div>

             
        </div>

        <div v-if="errorMessage" style="margin: auto; width: 80%; margin-top: 20%; font-size: 20px;">
            <p>Sorry, something went wrong during the generation of your report.</p>
            <p>Please email tyronebowes@gmail.com</p>
            <p>{{errorMessage}}</p>
            <p>and we will get back to you as soon as possible.</p>
        </div>

       

        <div id="autosomal-report" class="container" v-if="showTemplate">
            <span class="title">IRISH ORIGENES AUTOSOMAL DNA REPORT FOR TEST SUBJECT</span>
            <div id="stats">
                <div id="family-finder-stats">
                    <table>
                        <thead>
                        <tr id="familyFinder-head">
                            <th colspan="2">File Statistics</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Matches</td>
                            <td v-text="familyFinder.total_rows"/>
                        </tr>
                        <tr>
                            <td>Ancestral details</td>
                            <td v-text="familyFinder.ancestral_details"/>
                        </tr>
                        <tr>
                            <td>Percentage</td>
                            <td v-text="familyFinder.ancestral_details_percent.toFixed(2)"></td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <span><b>{{ familyFinder.ancestral_details_percent.toFixed(2) }}%</b> of the autosomal genetic relatives reveal ancestral information </span>
                <hr>

                <!-- <div id="country-stats">
                    <table>
                        <thead>
                        <tr>
                            <th>Country</th>
                            <th>Frequency</th>
                            <th>Percentage</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="entry in countryStats" :key="entry.country">
                            <td v-for="key in countryStatsColumns" :key="key">
                                {{entry[key]}}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div> -->

                <!-- <span><b>{{ countryStats[0].percentage }}%</b> Reveal Ancestral links with Ireland</span>
                <hr> -->

                <span class="title">IRISH COUNTY HOTSPOTS</span>
                <div style="margin-bottom: 20px;">
                    <p>The table and map below detail the frequency with which each county was found as a match to your ancestral details.</p>
                    <p><span style="font-size: 1.2rem;">County {{mainCounty}}</span> was identified as a potential DNA hotspot based on the uploaded file.</p>
                </div>

                <div id="county-stats" class="row">
                    <div class="">
                        <table id="county-tab">
                            <thead>
                            <tr>
                                <th>County</th>
                                <th>Frequency</th>
                                <th>Percentage</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="entry in countyStats"  :key="entry[0]">
                                <td :style="'background-color: ' + getHotSpotColor(entry[1])">
                                    {{entry[0]}}
                                </td>
                                <td :style="'background-color: ' + getHotSpotColor(entry[1])">
                                    {{entry[1]}}
                                </td>
                                <td :style="'background-color: ' + getHotSpotColor(entry[1])">
                                    {{entry[2].toFixed(2)}}
                                </td>
                            </tr>
                            
                            </tbody>
                        </table>
                    </div>

                    <GChart
                        id="chart_div"
                        type="GeoChart"
                        :data="geoChartData"
                        :options="geoChartCountiesOptions"
                        :settings="{ packages: ['corechart', 'table', 'map', 'geochart']}"
                    />
                </div>
            </div>

            <hr>

            <p><b>County {{ mainCounty.toUpperCase() }}</b> emerged as the strongest autosomal DNA hotspot.</p>
            <p style="margin-bottom: 30px;">The map below identifies your ancestral locations within County <b>{{ mainCounty }}</b></p>

            <GChart
                :settings="{ packages: ['corechart', 'table', 'map']}"
                id="chart_county"
                type="Map"
                :data="geoChartCountyData"
                :options="mapCountyOptions"
            />

            <hr>


            <div id="name-origins" v-if="showSurnames">
                <div>
                    <p class="title">SURNAMES REPORT</p>
                    <p>Below are the predicted origins for the surnames you have specified.</p>
                </div>
                <table>
                    <thead>
                    <tr>
                        <th>Surname</th>
                        <th>Predicted Origin</th>
                        <th>Max Shared DNA detected</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="entry in nameOrigins" :key="entry.name">
                        <td v-for="key in nameOriginsColumns" :key="key">
                            {{entry[key]}}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <div>
                <p>The map below shows other locations in Ireland related to your specified surnames</p>
            </div>

            <GChart
                id="chart_surnames"
                type="GeoChart"
                :data="geoChartSurnamesData"
                :options="chartSurnamesOptions"
                v-if="showSurnamesMap"
            />
        </div>
    </div>
</template>

<script>
    import api from './services/apiService';
    import {GChart} from 'vue-google-charts';
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';

    export default {
        name: 'autosomal-report-v2',
        components: {GChart, Loading},
        data: function () {
            return {
                reportReady: false,
                errorMessage: null,
                isLoading: true,
                fullPage: true,
                iconsUrl: 'https://icons.iconarchive.com/icons/icons-land/vista-map-markers/48/',
                familyFinder: [],
                countryStatsColumns: ['country', 'frequency', 'percentage'],
                countryStats: [],
                countyStatsColumns: ['county', 'frequency', 'percentage'],
                countyStats: [],
                geoChartCounties: [
                    ['LATITUDE', 'LONGITUDE', 'Provinces', 'Frequency'],
                ],
                geoChartCountiesOptions: {
                    region: 'IE',
                    width: 'auto',
                    height: '600',
                    resolution: 'provinces',
                    displayMode: 'marker',
                    enableRegionInteractivity: true,
                    colors: ['green', 'green', 'green', 'green', 'green', 'green', 'green', 'orange', 'orange', 'red'],
                    // colors: ['lightblue', 'lightblue', 'lightblue', 'yellow', 'yellow', 'yellow', 'orange', 'orange', 'orange', 'red'],
                    // colors: ['rgb(255,220,220)', 'rgb(255,200,200)', 'rgb(255,180,180)', 'rgb(255,160,160)', 'rgb(255,140,140)', 'rgb(255,120,120)', 'rgb(255,100,100)', 'rgb(255,80,80)', 'rgb(255,40,40)', 'rgb(255,0,0)'],
                },
                mapCountyData: [
                    ['Lat', 'Long', 'Name', 'Marker'],
                ],
                mapCountyOptions: {
                    showTooltip: true,
                    showInfoWindow: true,
                    zoomLevel: 9,
                    mapType: 'hybrid',
                    // mapType: 'satellite',
                    icons: {
                        blue: {
                            normal: 'https://maps.google.com/mapfiles/kml/paddle/ltblu-circle.png',
                        },
                        yellow: {
                            normal: 'https://maps.google.com/mapfiles/kml/paddle/ylw-circle.png',
                        },
                        orange: {
                            normal: 'https://maps.google.com/mapfiles/kml/paddle/orange-circle.png',
                        },
                        red: {
                            normal: 'https://maps.google.com/mapfiles/kml/paddle/red-circle.png',
                        }
                    },
                },
                chartSurnamesData: [
                    ['LATITUDE', 'LONGITUDE', 'Names', 'Max Shared DNA detected'],
                ],
                chartSurnamesOptions: {
                    region: 'IE',
                    width: 'auto',
                    height: '600',
                    resolution: 'provinces',
                    displayMode: 'marker',
                    enableRegionInteractivity: true,
                    // colorAxis: {
                    //     values: [1, 2, 3, 4, 5, 6, 7, 8],
                    //     colors: ['blue', 'green', 'purple', 'orange', 'red', 'black', 'brown', 'yellow']
                    // },
                    colorAxis: {colors: ['#03ace0', '#e7711c']},
                    sizeAxis: {
                        minSize: 7,
                        maxSize: 10
                    }
                },
                nameOrigins: [],
                nameOriginsColumns: ['name', 'origin', 'shared_dna'],
                countyColors: [],
                mainCounty: 'County '
            }
        },
        mounted() {
            let params = this.$route.params;
            let link = '/api/v2/autosomal/report?id=' + params.id + '&path=' + params.path + '&email=' + params.email;

            api.get(link).then(response => {
                const data = response.data;
                this.familyFinder = data.match_stats;
                // this.countryStats = data.stats.countryStats;
                this.countyStats = data.dna_hotspots;
                var countyData = data.dna_hotspots.map(c => {
                    return [c[3], c[4], c[0], c[1]]
                })
                this.geoChartCounties = [...this.geoChartCounties, ...countyData];
                this.mainCounty = countyData[0][2];
                var nameLocations = data.name_locations.filter(loc => {
                    return loc.address.toLowerCase() == this.mainCounty.toLowerCase()
                })
                .sort((a,b) => { return a.shared_dna - b.shared_dna })
                .reverse()

                var maxDna = nameLocations[0].shared_dna

                nameLocations = nameLocations.map(l => {
                    var color = 'blue'
                    var dna = l.shared_dna
                    if(dna > maxDna * 0.95){
                        color = 'red'
                    }
                    else if(dna > maxDna * 0.75){
                        color = 'orange'
                    }

                    return [Number(l.lat), Number(l.lng), l.name + ' - shared dna: ' + l.shared_dna, color]
                })
                .slice(0, 49)
                
                this.mapCountyData = [...this.mapCountyData, ...nameLocations];

                
                var nameOrigins = []
                for (const key in data.surname_origins){
                    nameOrigins.push(data.surname_origins[key])
                }

                var chartNamesData = []
                var color = 1
                nameOrigins.forEach(nm => {
                    
                    nm.locations.forEach(loc => {
                        var details = loc.county
                        if(loc.town){
                            details = loc.town + ", " + details
                        }
                        var dna = nm.max_shared_dna
                        
                        chartNamesData.push([
                            Number(loc.lat),
                            Number(loc.lng),
                            nm.original + ' : ' + details,
                            nm.max_shared_dna
                        ])
                    })
                    color++
                    
                })

                console.log('nameorigins:', nameOrigins)

                this.chartSurnamesData = [...this.chartSurnamesData, ...chartNamesData];
                console.log(this.chartSurnamesData)

                nameOrigins = nameOrigins.map(nm => {
                    var orig = 'Surname not associated with medieval Ireland'
                    if(nm.predicted_location){
                        orig = 'County ' + nm.predicted_location.county
                        if(nm.predicted_location.town){
                            orig = nm.predicted_location.town + ', ' + orig
                        }
                    }
                    
                    return {
                        name: nm.name,
                        origin: orig,
                        shared_dna: nm.max_shared_dna
                    }
                })
                this.nameOrigins = nameOrigins;
                // this.countyColors = data.countyColors;
                
                this.reportReady = true
            })
            .catch(err => {
                this.errorMessage = 'Quoting report id: ' + params.id + ' and ref: ' + params.path
            })
        },

        methods: {

            getHotSpotColor(curr){
                
                var maxp = this.countyStats[0][1]
                var percent = (curr / maxp) 
                var color = 'rgba(255,235,235,0.4)'

                if(curr > maxp * 0.99){
                    color = 'rgba(255,0,0,0.4)'
                }
                else if(curr > maxp * 0.9){
                    color = 'rgba(255,40,0,0.4)'
                }
                else if(curr > maxp * 0.8){
                    color = 'rgba(255,40,40,0.4)'
                }
                else if(curr > maxp * 0.75){
                    color = 'rgba(255,40,50,0.4)'
                }
                else if(curr > maxp * 0.7){
                    color = 'rgba(255,80,80,0.4)'
                }
                else if(curr > maxp * 0.6){
                    color = 'rgba(255,100,100,0.4)'
                }
                else if(curr > maxp * 0.5){
                    color = 'rgba(255,140,140,0.4)'
                }
                else if(curr > maxp * 0.4){
                    color = 'rgba(255,170,170,0.4)'
                }
                else if(curr > maxp * 0.3){
                    color = 'rgba(255,200,200,0.4)'
                }
                else if(curr > maxp * 0.2){
                    color = 'rgba(255,220,220,0.4)'
                }
                else if(curr > maxp * 0.1){
                    color = 'rgba(255,230,230,0.4)'
                }
                
                return color
            }
        },

        computed: {
            geoChartData() {
                return this.geoChartCounties;
            },
            geoChartCountyData() {
                return this.mapCountyData;
            },
            geoChartSurnamesData() {
                return this.chartSurnamesData;
            },
            showSurnames() {
                return this.nameOrigins.length > 0;
            },
            showSurnamesMap() {
                return this.chartSurnamesData.length > 1;
            },
            showTemplate() {
                return this.reportReady
            },
            getCountyColor() {
                return this.countyColors;
            }
        }
    }
</script>
