<template>
    <div class="container">
        <div style="box-sizing: content-box; z-index: 100">
            <GChart
                :settings="settings"
                :id="chartid"
                type="GeoChart"
                :data="geoChartSurnamesData"
                :options="options"
                :resizeDebounce="200"
            />
        </div>
        
    </div>
    
</template>

<script>
import {GChart} from 'vue-google-charts';

export default {
    name: 'SurnamesMap',

    components: {
        GChart
    },

    props: {
        chartid: {
            type: String,
            default: "chart_surnames"
        },
        settings: {
            type: Object,
            default: () => { 
                return { 
                    packages: ['corechart', 'table', 'map', 'geochart']
                } 
            }
        },
        chartheadings: {
            type: Array,
            default: () => { 
                return [
                    ['LATITUDE', 'LONGITUDE', 'Names', 'Total Locations']
                ]
            }
        },
        surnamesdata: {
            type: Array,
            default: () => {
                return []
            }
        },
        options: {
            type: Object,
            default: () => { 
                return {
                    region: 'IE',
                    // width: 'auto',
                    height: window.innerWidth < 400 ? '380' : '600',
                    resolution: 'provinces',
                    displayMode: 'marker',
                    enableRegionInteractivity: true,
                    // colorAxis: {
                    //     values: [1, 2, 3, 4, 5, 6, 7, 8],
                    //     colors: ['blue', 'green', 'purple', 'orange', 'red', 'black', 'brown', 'yellow']
                    // },
                    // colorAxis: {colors: ['#03ace0', '#e7711c']},
                    colorAxis: {colors: ['orange', 'blue', 'green', 'lightblue', 'brown', 'red', 'grey', 'yellow']},
                    sizeAxis: {
                        minSize: 7,
                        maxSize: 7
                    }
                } 
            }
        }
    },

    data(){
        return {

        }
    },

    computed: {
        geoChartSurnamesData(){
            // concatenate the headings and the surnames data
            let dataset = [...this.chartheadings, ...this.surnamesdata]
            return dataset
        }
    }
}
</script>