import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    VUE_APP_API_PATH: process.env.VUE_APP_API_PATH
  },
  getters: {
    VUE_APP_API_PATH: state => {
      return state.VUE_APP_API_PATH
    }
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
