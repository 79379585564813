import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import CancelPage from '../views/CancelPage.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: Home
  },
  {
    path: '/cancel',
    name: 'cancel',
    component: CancelPage
  },
  {
    path: '/privacy-policy',
    name: 'privacypolicy',
    component: PrivacyPolicy
  },
  {
    path: '/about',
    component: require('../components/AboutComponent.vue').default,
    name: 'about',
},
{
    path: '/how-it-works',
    component: require('../components/HowItWorks.vue').default,
    name: 'how-autosomal',
},
{
    path: '/contact',
    component: require('../components/ContactComponent.vue').default,
    name: 'contact',
},
{
    path: '/v2/autosomal',
    component: require('../components/AutosomalComponent_v2.vue').default,
    name: 'autosomal_v2',
},
{
    path: '/v2/autosomal/report/:id/:path/:email',
    component: require('../components/AutosomalReport_v2.vue').default,
    name: 'autosomal_report_v2'
},
{
    path: '/autosomal',
    // component: require('../components/AutosomalComponent.vue').default,
    component: require('../components/AutosomalComponent_v2.vue').default,
    name: 'autosomal',
},
{
    path: '/upload',
    component: require('../components/UploadComponent.vue').default,
    name: 'upload',
},
{
    path: '/report/:id/:email',
    component: require('../components/ReportComponent.vue').default,
    name: '/report'
},

{
    path: '/autosomal/report/:id/:path/:email',
    component: require('../components/AutosomalReport.vue').default,
    name: 'autosomal-report'
},
{
    path: '/surnames',
    component: require('../components/SurnamesForm.vue').default,
    name: 'surnamesform',
},

{
    path: '/surnames/report/:id/:code/:email',
    component: require('../components/SurnamesReport.vue').default,
    name: 'surnamesreport',
},

// {
//     path: '/admin/secret/logs',
//     component: require('./components/Logs.vue').default,
//     name: 'logs'
// },
]

const router = new VueRouter({
  mode: 'history',
  linkActiveClass: 'is-active',
  routes
})

export default router
