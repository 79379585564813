<template>
    <div class="container">
          <div class="row">
              <div class="col">
                  <div class="" style="min-height: 500px;">
                      <div >
                          <h2 class="mb-3 mt-3">Irish Origenes Privacy Policy</h2>
                          <p>
                          </p>
                      </div>
                      
                      
                  </div>
              </div>
  
              
          </div>
      </div>
  </template>
  
  <script>
  // @ is an alias to /src
  
  
  export default {
    name: 'Privacy Policy',
    components: {
      
    }
  }
  </script>
  
  <style scoped>
  
  </style>
  