<template>
    <div id="template-autosomal-report">
        <div id="preloader" v-if="!showTemplate" style="min-height: 500px;">
            <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
            <div class="vld-overlay is-active is-full-page preloader-text">
                <span>We are now analysing your report. Please wait up to 2 minutes for this analysis to complete.</span>
            </div>
        </div>
        <div id="autosomal-report" class="container" v-if="showTemplate">
            <span class="title">IRISH ORIGENES AUTOSOMAL DNA REPORT FOR TEST SUBJECT</span>
            <div id="stats">
                <div id="family-finder-stats">
                    <table>
                        <thead>
                        <tr id="familyFinder-head">
                            <th colspan="2">FamilyFinder STATS</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Matches</td>
                            <td v-text="familyFinder.matches"/>
                        </tr>
                        <tr>
                            <td>Ancestral details</td>
                            <td v-text="familyFinder.ancestralDetails"/>
                        </tr>
                        <tr>
                            <td>Percentage</td>
                            <td v-text="familyFinder.percentage"></td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <span><b>{{ familyFinder.percentage }}%</b> of the autosomal genetic relatives reveal ancestral information </span>
                <hr>

                <div id="country-stats">
                    <table>
                        <thead>
                        <tr>
                            <th>Country</th>
                            <th>Frequency</th>
                            <th>Percentage</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="entry in countryStats" :key="entry.country">
                            <td v-for="key in countryStatsColumns" :key="key">
                                {{entry[key]}}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <span><b>{{ countryStats[0].percentage }}%</b> Reveal Ancestral links with Ireland</span>
                <hr>

                <span class="title">IRISH COUNTY BREAKDOWN</span>

                <div id="county-stats">
                    <table id="county-tab">
                        <thead>
                        <tr>
                            <th>County</th>
                            <th>Frequency</th>
                            <th>Percentage</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="entry in countyStats.Ulster" class="ulster" :key="entry.county">
                            <td v-for="key in countyStatsColumns" :key="key"
                                :style="key !== 'county' ? `background-color: ${getCountyColor[entry.county]};` : false">
                                {{entry[key]}}
                            </td>
                        </tr>
                        <tr v-for="entry in countyStats.Leinster" class="leinster" :key="entry.county">
                            <td v-for="key in countyStatsColumns" :key="key"
                                :style="key !== 'county' ? `background-color: ${getCountyColor[entry.county]};` : false">
                                {{entry[key]}}
                            </td>
                        </tr>
                        <tr v-for="entry in countyStats.Munster" class="munster" :key="entry.county">
                            <td v-for="key in countyStatsColumns" :key="key"
                                :style="key !== 'county' ? `background-color: ${getCountyColor[entry.county]};` : false">
                                {{entry[key]}}
                            </td>
                        </tr>
                        <tr v-for="entry in countyStats.Connaught" class="connaught" :key="entry.county">
                            <td v-for="key in countyStatsColumns" :key="key"
                                :style="key !== 'county' ? `background-color: ${getCountyColor[entry.county]};` : false">
                                {{entry[key]}}
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <GChart
                        id="chart_div"
                        type="GeoChart"
                        :data="geoChartData"
                        :options="geoChartCountiesOptions"
                        :settings="{ packages: ['corechart', 'table', 'map', 'geochart']}"
                    />
                </div>
            </div>

            <hr>

            <span><b>{{ mainCounty.toUpperCase() }}</b> emerged as the strongest autosomal DNA hotspot Plotted ancestral locations within
                    <b>{{ mainCounty }}</b></span>

            <GChart
                :settings="{ packages: ['corechart', 'table', 'map']}"
                id="chart_county"
                type="Map"
                :data="geoChartCountyData"
                :options="mapCountyOptions"
            />

            <hr>

            <div id="name-origins" v-if="showSurnames">
                <table>
                    <thead>
                    <tr>
                        <th>Surname</th>
                        <th>Predicted Origin</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="entry in nameOrigins" :key="entry.name">
                        <td v-for="key in nameOriginsColumns" :key="key">
                            {{entry[key]}}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <GChart
                id="chart_surnames"
                type="GeoChart"
                :data="geoChartSurnamesData"
                :options="chartSurnamesOptions"
                v-if="showSurnamesMap"
            />
        </div>
    </div>
</template>

<script>
    import api from '../components/services/apiService';
    import {GChart} from 'vue-google-charts';
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';

    export default {
        name: 'autosomal-report',
        components: {GChart, Loading},
        data: function () {
            return {
                isLoading: true,
                fullPage: true,
                iconsUrl: 'https://icons.iconarchive.com/icons/icons-land/vista-map-markers/48/',
                familyFinder: [],
                countryStatsColumns: ['country', 'frequency', 'percentage'],
                countryStats: [],
                countyStatsColumns: ['county', 'frequency', 'percentage'],
                countyStats: [],
                geoChartCounties: [
                    ['LATITUDE', 'LONGITUDE', 'Provinces', 'Frequency'],
                ],
                geoChartCountiesOptions: {
                    region: 'IE',
                    width: 'auto',
                    height: 'auto',
                    resolution: 'provinces',
                    displayMode: 'marker',
                    enableRegionInteractivity: true,
                    colors: ['green', 'green', 'green', 'green', 'green', 'green', 'green', 'green', 'green', 'red'],
                },
                mapCountyData: [
                    ['Lat', 'Long', 'Name', 'Marker'],
                ],
                mapCountyOptions: {
                    showTooltip: true,
                    showInfoWindow: true,
                    zoomLevel: 9,
                    mapType: 'hybrid',
                    // mapType: 'satellite',
                    icons: {
                        blue: {
                            normal: 'http://maps.google.com/mapfiles/kml/paddle/ltblu-circle.png',
                        },
                        yellow: {
                            normal: 'http://maps.google.com/mapfiles/kml/paddle/ylw-circle.png',
                        },
                        orange: {
                            normal: 'http://maps.google.com/mapfiles/kml/paddle/orange-circle.png',
                        },
                        red: {
                            normal: 'http://maps.google.com/mapfiles/kml/paddle/red-circle.png',
                        }
                    },
                },
                chartSurnamesData: [
                    ['LATITUDE', 'LONGITUDE', 'Names', 'Color'],
                ],
                chartSurnamesOptions: {
                    region: 'IE',
                    width: 'auto',
                    height: 'auto',
                    resolution: 'provinces',
                    displayMode: 'text',
                    enableRegionInteractivity: true,
                    colorAxis: {
                        values: [1, 2, 3, 4, 5, 6, 7, 8],
                        colors: ['blue', 'green', 'purple', 'orange', 'red', 'black', 'brown', 'yellow']
                    },
                    sizeAxis: {
                        minSize: 14,
                        maxSize: 14
                    }
                },
                nameOrigins: [],
                nameOriginsColumns: ['name', 'origin'],
                countyColors: [],
                mainCounty: 'County '
            }
        },
        mounted() {
            let params = this.$route.params;
            let link = '/api/autosomal/report?id=' + params.id + '&path=' + params.path + '&email=' + params.email;

            api.get(link).then(response => {
                const data = response.data;
                this.familyFinder = data.stats.matchStats;
                this.countryStats = data.stats.countryStats;
                this.countyStats = data.stats.countyStats;
                this.geoChartCounties = [...this.geoChartCounties, ...data.countiesData];
                this.mapCountyData = [...this.mapCountyData, ...data.mapSurnamesData];
                this.chartSurnamesData = [...this.chartSurnamesData, ...data.chartNamesData];
                this.nameOrigins = data.nameOrigins;
                this.countyColors = data.countyColors;
                this.mainCounty += data.mainCounty;
            });
        },
        computed: {
            geoChartData() {
                return this.geoChartCounties;
            },
            geoChartCountyData() {
                return this.mapCountyData;
            },
            geoChartSurnamesData() {
                return this.chartSurnamesData;
            },
            showSurnames() {
                return this.nameOrigins.length > 0;
            },
            showSurnamesMap() {
                return this.chartSurnamesData.length > 1;
            },
            showTemplate() {
                return this.countryStats.length > 0;
            },
            getCountyColor() {
                return this.countyColors;
            }
        }
    }
</script>
