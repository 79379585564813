<template>
    <div class="header">
        <div class="header-logo">
            <a class="logo-link" href="/"><h1> Irish Origenes </h1></a>
        </div>
        <div class="header-links">
            <a class="header-link" href="/about">About</a>
            <a class="header-link" href="/how-it-works">How It Works</a>
            <a class="header-link" href="/contact">Contact</a>
            <a class="header-link" href="/surnames">Surname Search</a>
            <a class="header-link" href="/v2/autosomal">Autosomal</a>
            <a class="header-link" href="/upload">Y-DNA</a>
            <a class="header-link" href="/">Start Now</a>
        </div>

        <div class="hamburger-menu mobile">
            <input id="menu__toggle" type="checkbox"/>
            <label class="menu__btn" for="menu__toggle">
                <span></span>
            </label>

            <ul class="menu__box">
                <li><a class="menu__item" href="/about">About Me</a>
                <li><a class="menu__item" href="/how-it-works">How It Works</a></li>
                <li><a class="menu__item" href="/contact">Contact</a></li>
                <li><a class="menu__item" href="/surnames">Surname Search</a></li>
                <li><a class="menu__item" href="/v2/autosomal">Autosomal</a></li>
                <li><a class="menu__item" href="/upload">Y-DNA</a></li>
                <li><a class="menu__item" href="/">Start Now</a></li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'site-header',
    }
</script>
