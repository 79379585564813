<template>
    <div class="page-split contact-page">

        <div class="page-split--left">
            <div class="container">
                <h1 class="contact-title"> Contact Me </h1>
                <form action="https://formspree.io/tyronebowes@gmail.com" method="POST">

                    <div class="Input">
                        <label class="Input-label" for="fname">Name</label>
                        <input class="Input-text" type="text" id="fname" name="name" placeholder="Your name..">
                    </div>

                    <div class="Input">
                        <input class="Input-text" type="text" id="email" name="email" placeholder="Your e-mail..">
                        <label class="Input-label" for="email">E-mail</label>
                    </div>

                    <div class="Input">
                        <input class="Input-text" type="text" id="message" name="message"
                               placeholder="Write something.." style="height:150px">
                        <label class="Input-label" for="message">Message</label>
                    </div>

                    <input type="submit" value="Submit">

                </form>
            </div>
        </div>

        <div class="page-split--right">
            <div class="page-background-section"
                 style="background: url('/static/io/images/Blank_Ireland.svg') center no-repeat">
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'contact-component',
        mounted() {
        }
    }
</script>

<style>
    .page-background-section {

    }
</style>
